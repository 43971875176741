<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tabla Dinámica Turnos Internos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Turnos</li>
                  <li class="breadcrumb-item active">Tabla Dinámica</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-header pt-2 pb-2">
            <div class="row">
              <div class="col-md-6">
                <h5>Filtros</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Acordeón de filtros -->
            <div class="row">
              <!---- Filtro Fecha inicial ---->
              <div class="form-group col-md-2">
                <label>Fecha inicial</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="filtros.fecha_inicial"
                  :class="
                    $v.filtros.fecha_inicial.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validaFechas()"
                />
              </div>
              <!---- Filtro Fecha final ---->
              <div class="form-group col-md-2">
                <label>Fecha final</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="filtros.fecha_final"
                  :class="
                    $v.filtros.fecha_final.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @input="validaFechas()"
                />
              </div>
              <!---- Filtro Sitio ---->
              <div class="form-group col-md-2">
                <label>Sitio</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="sitio"
                  placeholder="Sitios"
                  label="nombre"
                  :options="listasForms.sitios"
                  class="form-control form-control-sm p-0"
                  @input="getSelectSitios()"
                ></v-select>
              </div>
              <!---- Filtro Vehículo ---->
              <div class="form-group col-md-2">
                <label>Vehículo</label>
                <input
                  type="text"
                  placeholder="Vehículo"
                  v-model="filtros.vehiculo_id"
                  class="form-control form-control-sm"
                />
              </div>
              <!---- Filtro Turno ---->
              <div class="form-group col-md-2">
                <label>Turno</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="turno"
                  placeholder="Turno"
                  label="nombre"
                  :options="listasForms.turnos"
                  @input="getSelectTurno()"
                ></v-select>
              </div>
              <div style="width: 50px">
                <div class="btn-group col-md-4">
                  <button
                    type="button"
                    v-show="!$v.filtros.$invalid"
                    class="btn bg-navy btn-sm"
                    @click="generarPivotTable()"
                  >
                    Generar Búsqueda
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <pivot-table-component
                      v-if="pivotData"
                      :pivotData="pivotData"
                      :aggregator-name="aggregatorName"
                      :renderer-name="rendererName"
                    >
                    </pivot-table-component>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "TepTurnoPivotTable",
  components: {
    PivotTableComponent,
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      dataPivot: [],
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      sitio: {},
      turno: {},
      filtros: {
        sitio_id: null,
        vehiculo_id: null,
        fecha_inicial: null,
        fecha_final: null,
        turno: null,
      },
      listasForms: {
        sitios: [],
        turnos: [],
      },
    };
  },
  validations: {
    filtros: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },
  methods: {
    validaFechas() {
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        var fecha_menor = new Date(this.filtros.fecha_inicial);
        var fecha_mayor = new Date(this.filtros.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        fecha_menor = new Date(this.filtros.fecha_inicial).getTime();
        fecha_mayor = new Date(this.filtros.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 30) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a un mes...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
    back() {
      return this.$router.replace("/Tep/Turnos");
    },

    async getSitios() {
      await axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    async getTurnos() {
      axios.get("/api/admin/turnos/lista").then((response) => {
        this.listasForms.turnos = response.data;
      });
    },

    getSelectSitios() {
      if (this.sitio != null) {
        this.filtros.sitio_id = this.sitio.id;
      }
    },

    getSelectTurno() {
      if (this.turno != null) {
        this.filtros.turno = this.turno.id;
      }
    },

    generarPivotTable() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/turno/pivotTable",
        data: this.filtros,
      })
        .then((response) => {
          this.cargando = false;
          this.pivotData = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getSitios();
    await this.getTurnos();
    this.cargando = false;
  },
};
</script>
